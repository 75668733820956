import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SEO from '../components/seo';
import './index.scss';
import ProductHeader from '../components/product-header';
import ArrowRightIcon from '!svg-react-loader!../images/vectors/arrow_right.svg';
import SALogo from '!svg-react-loader!../images/vectors/sa-logo.svg';
import SAFeedback from './sa-feedback';
import BookDemoPage from '../components/book-demo';
import { OfferingItem, OfferingItemContainer } from '../components/extra-components';
import DetailedSpecs from '../components/detailed-specs';
import ComingSoon from '../components/coming-soon';
import * as Constants from '../utils/constants';
import { CTypes } from '../utils/constants';


const SmartAssistantLogo = () => {
  return (
    <SALogo width={25} height={28} alt={'smart assistant logo'} />
  );
};

const baseOfferingItemsList = [
  {
    title: 'Smart Records',
    description: 'Digitize health records of your patients with zero habit change and ABDM compliance'
  },
  {
    title: 'Smart Appointments',
    description: 'Create your digital identity and manage appointments with patients & medical representatives'
  },
  {
    title: 'Smart Desk',
    description: 'Manage patient engagement over WhatsApp with automated notifications'
  },
  {
    title: 'Smart Account',
    description: 'Get summary of your earnings and tools to manage patient & vendor transactions'
  },
  {
    title: 'Smart Library',
    description: 'Access curated articles, journals, surveys, CMEs, webinars and news relevant to your practice'
  },
  {
    title: 'Smart Analytics',
    description: 'Get insights around your subscribed micro apps'
  }
];

const SABaseOfferingPage = () => {
  const viewDetailsClicked = (page) => {
    return (e) => {
      e.stopPropagation();
      console.log('view details clicked', page, e);
      return true;
    };
  };
  return (
    <>
      <div className='micro-app-offering-header'>
        <h4>Base Offering</h4>
        <p className='offering-pricing'>
          Includes our six most popular micro apps and MedTrail support. <br />
          Price: ₹18K + GST / doctor / year
        </p>
      </div>
      <OfferingItemContainer list={baseOfferingItemsList} fullWidth />
    </>
  );
};

const upgradesContentList = [
  {
    title: 'Smart Campaigns',
    description: 'Run awareness campaigns for existing patients and reach out to wider audience to serve new patients'
  },
  {
    title: 'Smart Dispensary',
    description: 'Automate workflows, improve operating income and get real-time visibility into your dispensary operations'
  },
  {
    title: 'Smart Diagnostics',
    description: 'Offer portfolio of your trusted labs to patients, accelerate feedback on their reports and improve operating income'
  }
];

const SmartAssistantUpgradesPage = () => {

  const viewDetailsClicked = (page) => {
    return (e) => {
      e.stopPropagation();
      console.log('view details clicked', page, e);
      return true;
    };
  };
  return (
    <div>
      <div className='micro-app-offering-header'>
        <h4>Upgrades</h4>
        <p className='offering-pricing'>
          Powerful micro apps to scale up your practice and provide end-to-end care to your patients. <br />
          Price: Pay-per-use
        </p>
      </div>

      <OfferingItemContainer list={upgradesContentList} fullWidth />
    </div>
  );
};


const SAHospitalsDetailsPage = ({ location, navigate }) => {

  // useEffect(() => {
  //   window.scrollTo({ behavior: 'auto', top: 0 });
  // }, []);

  const variants = {
    hidden: (custom) => {
      return {
        opacity: 0,
        y: 0
      };
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0
    })
  };

  return (
    <div className='mt-page-root container'>
      <SEO title="Smart Assistant - Base Offering" />
      <div className='page-fixed-header container'>
        <ProductHeader
          title={'Smart Assistant'}
          productImage={SmartAssistantLogo}
          backLinkTo={`/#home-SA`}
          featureText={`for Hospitals`}
          cType={CTypes.SA_HOSPITALS}
        />
      </div>
      <motion.div
        className="container-fluid main-container-full sa-page-scroller"
        initial={'hidden'}
        animate={'visible'}
        variants={{
          visible: {
            transition: {
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          hidden: {
            transition: {
              duration: 0.3
            }
          }
        }}
      >
        <motion.section variants={variants}>
          <div className='sa-base-description'>
            Modular digital assistant that is designed as an operating system for hospitals. Its powerful micro apps
            digitise doctor interactions with all stakeholders, automate workflows to generate higher operating income
            and
            optimise for end-to-end care of patients with minimal operational hassle.
          </div>
        </motion.section>

        <div className='row mt-offering-section-container'>
          <motion.section className='row' variants={variants}>
            <SABaseOfferingPage />
          </motion.section>

          <motion.section className='row' variants={variants}>
            <SmartAssistantUpgradesPage />
          </motion.section>
        </div>

        {/*<div className='row'>*/}
        {/*  <div className='col-lg-4' />*/}
        {/*  <motion.section className='col-lg-4' variants={variants}>*/}
        {/*    <DetailedSpecs>*/}
        {/*      <ComingSoon autoHeight />*/}
        {/*    </DetailedSpecs>*/}
        {/*  </motion.section>*/}
        {/*</div>*/}


        <motion.section variants={variants}>
          <div className='empty-space' />
          <SAFeedback />
        </motion.section>

      </motion.div>
      <div className='mobile'>
        <BookDemoPage cType={CTypes.SA_HOSPITALS} location={location} navigate={navigate} />
      </div>
      {/*<div className='desktop'>*/}
      {/*  <BookDemoPage location={location} navigate={navigate} />*/}
      {/*</div>*/}
    </div>
  );
};

export default SAHospitalsDetailsPage;
